@if (enrollmentAdvice) {
<div>
  <h3 class="grow">Degree(s) and Major OR Pathway</h3>
  <div class="flx spread">

    @if (academicEvaluationUri) {
    <a [href]="academicEvaluationUri.url" target="_blank">{{academicEvaluationUri.label}}
      <i class="material-icons">open_in_new</i></a>
    }

    @if (degreePlanUri && showDegreePlan && enrollmentAdvice.levelCode !== 'GS') {
    <a [href]="degreePlanUri.url" target="_blank">
      {{degreePlanUri.label}}<i class="material-icons">open_in_new</i>
    </a>
    }

    @if (graduateDegreePlanUri && showDegreePlan && enrollmentAdvice.levelCode === 'GS') {
    <a [href]="graduateDegreePlanUri.url" target="_blank">{{graduateDegreePlanUri.label}}<i
        class="material-icons">open_in_new</i></a>
    }

  </div>
  <hr class="clear half">
  <ul class="items">
    @for (term of termInfo; track $index) {
    <li>

      @if (term.term_desc) {
      <h3>{{ term.term_desc }}</h3>
      }

      @if (term.degree !== '') {
      <div class="flx spread indent">
        <h4 class="shrink">Degree:</h4>
        <div class="grow">{{ term.degree_code }}–{{ term.degree }}</div>
      </div>
      }

      @if (term.major !== '') {
      <div class="flx spread indent">
        <h4 class="shrink">Major OR Pathway:</h4>
        <div class="grow">{{ term.major_code }}–{{ term.major }}</div>
      </div>
      }

      @if (term.concent) {
      <div class="flx spread indent">
        <h4 class="shrink">Concentration:</h4>
        <div class="grow">{{ term.concent_code }}–{{ term.concent }}</div>
      </div>
      }

    </li>
    }
  </ul>
</div>
}