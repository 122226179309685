<h3 mat-dialog-title>Select User Role</h3>

<div mat-dialog-content>
  <div class="row">
    <mat-form-field class="full-width">
      <mat-label>Role</mat-label>
      <mat-select (selectionChange)="setUserRole($event)">
        @for (persona of data.personas; track $index) {
        <mat-option [value]="persona">
          {{ persona.label }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>