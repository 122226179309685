import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { APIOptions } from '../types';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  iframe: HTMLIFrameElement;
  refreshLock = false;
  mode: AuthenticationMode = 'GLUU';
  oidcUserSnapshot: User;
  userSnapshot: GluuUserModel;

  authUserEvents: Subject<User | void> = new Subject();
  userEvents: Subject<GluuUserModel> = new Subject();
  embeddedAuthSuccess: Subject<void> = new Subject();
  subscription: Subscription;
  showLogin = false;
  constructor(
    private loadingService: LoadingService,
    private loginService: LoginService
  ) {
    if (!(window as any).authService) {
      (window as any).authService = this;
    }
    this.subscription = this.loginService.events.subscribe(v => this.setUser(v as User));
  }

  get User(): Promise<User> {
    return this.getUser() as Promise<User>;
  }

  async getHeaders(): Promise<{ [key: string]: string }> {
    if (this.mode === 'GLUU') {
      return this.User
        .then(user => ({ Authorization: `Bearer ${user.access_token}` }));
    }
    return Promise.resolve({});
  }

  async logout(): Promise<void> {
    window.location.href = environment.loginConfig.logout;
  }

  async getUser(): Promise<void | User> {
    if (this.oidcUserSnapshot) {
      return this.oidcUserSnapshot;
    }

    this.loadingService.start('Authenticating');
    this.loginService.recover();
    // let u = await NextObserved(this.authUserEvents) || await NextObserved(this.authUserEvents);
    let u = await NextObserved(this.authUserEvents);
    if (!u) {
      this.showLogin = true;
      u = await NextObserved(this.authUserEvents);
    }

    this.loadingService.stop('Authenticating');
    return Promise.resolve(u);
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    this.mode = 'GLUU';
    return !!(await this.getUser());
  }

  async setUser(user: User) {
    this.refreshLock = false;
    if (user && (!user.profile || Array.from(Object.keys(user.profile)).length === 0)) {
      this.showLogin = true;
    } else if (this && user) {
      this.showLogin = false;
      this.oidcUserSnapshot = user;
      this.userSnapshot = {
        authorizationType: 'GLUU',
        campusId: user.profile.user_name as string,
        pantherId: user.profile.gsupersonpantherid as string,
        department: user.profile.departmentName as string || null,
        isFaculty: user.profile.gsuPersonAffiliation ? JSON.stringify(user.profile.eduPersonAffilation).indexOf('Faculty') >= 0 : false,
        isStaff: user.profile.eduPersonAffilation ? JSON.stringify(user.profile.eduPersonAffilation).indexOf('Staff') >= 0 : false,
        isStudent: user.profile.eduPersonAffilation ? JSON.stringify(user.profile.eduPersonAffilation).indexOf('Student') >= 0 : false,
        email: user.profile.email,
        givenName: user.profile.given_name,
        familyName: user.profile.family_name,
        collegeCode: user.profile.gsuPersonStudentCollegeCode as string,
        profile: user.profile
      };
      this.userEvents.next(this.userSnapshot);
      this.authUserEvents.next(this.oidcUserSnapshot);
    }
  }

  async requestAuthenticator(options: APIOptions): Promise<APIOptions> {
    if (this.mode === 'GLUU') {
      const headers = {
        ...options.headers,
        ...(await this.getHeaders())
      };
      options.headers = headers;
    }
    return options;
  }

  requestAuthenticatorFactory(): (options: APIOptions) => Promise<APIOptions> {
    return (options: APIOptions) => this.requestAuthenticator(options);
  }
}

export type AuthenticationMode = 'GLUU' | false | undefined | null;

export interface UserModel {
  pantherId: string;
  email: string;
  campusId: string;
  givenName: string;
  familyName: string;
  department: string | null;
  isStudent: boolean;
  isFaculty: boolean;
  isStaff: boolean;
  collegeCode: string;
  profile: any;
}
export interface GluuUserModel extends UserModel {
  authorizationType: 'GLUU';
}

function NextObserved<T>(s: Subject<T>): Promise<T> {
  let resolve: (value: T | PromiseLike<T>) => void;
  const p = new Promise<T>((r) => resolve = r);
  const sub = s.subscribe(v => {
    sub.unsubscribe();
    resolve(v);
  });
  return p;
}

export interface User {
  access_token: string;
  expires_at?: number;
  id_token?: string;
  profile: any;
  refresh_token?: string;
  scope?: string;
  session_state?: null | string;
  token_type: string;
  url_state?: string;
  userState?: unknown;
}
