import { PopoverLinkComponent } from "./../../popover-link/popover-link.component";
import { Component, Input } from "@angular/core";
import { SemesterTerm } from "../../../models/student-transform.model";

@Component({
  selector: "gsu-bill-waivers",
  standalone: true,
  templateUrl: "bill-waivers.component.html",
  styleUrls: ["bill-waivers.component.scss"],
  imports: [PopoverLinkComponent],
})
export class BillWaiversComponent {
  @Input() titleLink: string;
  @Input() terms: SemesterTerm[];
}
