import { CurriculumComponent } from "./curriculum/curriculum.component";
import { DegreesAndMajorPathwaysComponent } from "./degrees-and-major-pathways/degrees-and-major-pathways.component";
import { GpasComponent } from "./gpas/gpas.component";
import { RegistrationTermComponent } from "./registration-term/registration-term.component";
import { DynamicTextsComponent } from "./../dynamic-texts/dynamic-texts.component";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Student } from "../../models/student-transform.model";
import { StudentResource } from "../../models/student-resource.model";
import { OpenService } from "../../services/open.service";
import { StudentResourceService } from "../../services/student-resource.service";
import { EnrollmentAdvice } from "../../models/student-transform.model";
import { StudentAdvisorComponent } from "./student-advisor/student-advisor.component";
import { MessageParagraphsPipe } from "../../pipes/messageParagraphs.pipe";

@Component({
  standalone: true,
  selector: "registration",
  templateUrl: "registration.component.html",
  styleUrls: ["registration.component.scss"],
  imports: [
    DynamicTextsComponent,
    RegistrationTermComponent,
    GpasComponent,
    DegreesAndMajorPathwaysComponent,
    CurriculumComponent,
    StudentAdvisorComponent,
    MessageParagraphsPipe,
  ],
})
export class RegistrationComponent implements OnChanges {
  private className = "RegistrationComponent";

  @Input() student: Student;

  viewCourseScheduleResource: StudentResource;
  showAlert: boolean;
  enrollmentAdvice: EnrollmentAdvice;
  showPathways: boolean;
  pantherAnswerText: any;
  applyToGraduateUri: any;
  actionRequiredUri: any;
  addDropResource: any;
  registrationResource: any;
  hasHolds: boolean;
  hasVisibleTerms = true;
  academicEvaluationUri: StudentResource | undefined;
  academicEvaluationInfoUri: StudentResource | undefined;
  pantherAnswerResource: any;

  constructor(
    private openService: OpenService,
    studentResourceService: StudentResourceService
  ) {
    const fn: string = ">" + this.className + "#constructor";

    this.hasHolds = false;
    this.showAlert = false;
    const vcrs = studentResourceService.getResource(
      "view-course-enrollment",
      "view-course-schedule"
    );
    if (vcrs) this.viewCourseScheduleResource = vcrs;
    else console.error(fn + ": invalid CourseScheduleResource = " + vcrs);
    this.addDropResource = studentResourceService.getResource(
      "all-students",
      "add-drop-withdraw-classes"
    );
    this.academicEvaluationUri = studentResourceService.getResource(
      "my-registration",
      "academic-evaluation"
    );
    this.academicEvaluationInfoUri = studentResourceService.getResource(
      "my-registration",
      "academic-evaluation-info"
    );
    this.applyToGraduateUri = studentResourceService.getResource(
      "updates-requests-student-record",
      "application-for-graduation"
    );
    this.pantherAnswerResource = studentResourceService.getResource(
      "my-registration",
      "panther-answer"
    );
    this.actionRequiredUri = studentResourceService.getResource(
      "my-registration",
      "action-required"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fn: string = this.className + "#ngOnChanges";
    if (
      changes.student.currentValue !== undefined &&
      this.student.enrollmentAdvice
    ) {
      this.enrollmentAdvice = this.student.enrollmentAdvice;
      this.showPathways =
        this.enrollmentAdvice &&
        this.enrollmentAdvice.degreeMajors[0] &&
        !(<any>this.enrollmentAdvice.degreeMajors[0]).term_code;
      this.enrollmentAdvice.termList = this.enrollmentAdvice?.termList?.sort(
        (a, b) => parseInt(a.termCode, 10) - parseInt(b.termCode, 10)
      );
      this.hasHolds = this.enrollmentAdvice.hasHolds;
      this.showAlert = this.hasHolds;
      this.hasVisibleTerms = !!this.enrollmentAdvice.termList?.find(
        (t) => t.visible
      );
    }
  }

  applyToGraduate() {
    return this.openService.openUrl(this.applyToGraduateUri.url);
  }

  evaluateGradButton(): boolean {
    if (
      this.enrollmentAdvice.levelCode === "GS" ||
      this.enrollmentAdvice.levelCode === "LW"
    )
      return true;
    return this.enrollmentAdvice.grepGrel || false;
  }

  addDropClasses() {
    this.openService.openUrl(this.addDropResource.url);
  }
}
