import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Student } from "../../models/student-transform.model";
import { FinancialAidComponent } from "../financial-aid/financial-aid.component";
import { MessageParagraphsPipe } from "../../pipes/messageParagraphs.pipe";

@Component({
  standalone: true,
  selector: "financial-aid-modal",
  templateUrl: "financial-aid-modal.component.html",
  styleUrls: ["financial-aid-modal.component.scss"],
  imports: [FinancialAidComponent, MessageParagraphsPipe],
})
export class FinancialAidModalComponent {
  className = "FinancialAidModalComponent";
  student: Student;
  aidYear: any;

  constructor(
    public dialogRef: MatDialogRef<FinancialAidModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.student = data.student;
    this.aidYear = data.aidYear;
  }

  dismiss() {
    this.dialogRef.close();
  }
}
