import { SafePipe } from "../../pipes/safe.pipe";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { EnvService } from "../../services/env.service";
import { environment } from "../../environments/environment";

@Component({
  standalone: true,
  selector: "cross-platform-messages",
  templateUrl: "./cross-platform-messages.component.html",
  styleUrls: ["./cross-platform-messages.component.scss"],
  imports: [NgxSkeletonLoaderModule, SafePipe],
})
export class CrossPlatformMessagesComponent implements OnInit, AfterViewInit {
  crossPlatformSource =
    (EnvService.LAB ? "https://cdn-qa.gsu.edu" : environment.cdnDomain) +
    "/apps/cross-platform-messages" +
    (EnvService.LAB ? "-blab" : "");
  targetOrigin =
    (EnvService.LAB ? "https://cdn-qa.gsu.edu" : environment.cdnDomain) + "/";
  iframe: HTMLIFrameElement;
  isLoading = true;
  @Input() user;
  @Input() userPreferences;
  @Input() personas;
  @Input() persona;
  @Input() application: string = "paws-portal";
  @Input() student;
  @Input() targetedLists;

  constructor() { }

  ngOnChanges() {
    try {
      this.updateContext();
    } catch (e) {
      // console.log(e);
    }
  }

  ngAfterViewInit() {
    this.iframe = document.querySelector("#messageFrame");
    this.iframe.onload = () => {
      this.updateContext();
    };
  }

  ngOnInit(): void {
    window.addEventListener("message", (event) => {
      try {
        if (event.origin === environment.cdnDomain && event.data.height) {
          this.isLoading = false;
          this.iframe.style.height = event.data.height + "px";
          this.iframe.style.opacity = "1.0";
        }
        if (
          event.origin === environment.cdnDomain &&
          event.data.type &&
          event.data.type === "GET_USER"
        ) {
          this.iframe?.contentWindow.postMessage(
            { user: this.user },
            this.targetOrigin
          );
        }
      } catch (e) {
        // suppress to "look good"
        // console.log(e);
      }
    });
  }

  updateContext() {
    this.iframe?.contentWindow.postMessage(
      {
        user: this.user,
        userPreferences: this.userPreferences,
        personas: this.personas,
        persona: this.persona,
        student: this.student,
        targetedLists: this.targetedLists,
      },
      this.targetOrigin
    );
  }
}
