@if (menu) {
<button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button animated fadeIn">
  <mat-icon>menu</mat-icon>
</button>
}

<mat-menu #menu="matMenu" class="header-menu" yPosition="below">

  @if (currentMenu) {

  @if (currentMenu.parent) {
  <button mat-menu-item (click)='navigate(currentMenu.parent);$event.stopPropagation()'>
    <mat-icon>navigate_before</mat-icon>
    <strong>{{currentMenu.parent.label}}</strong>
  </button>
  }

  @for (item of currentMenu.items; track $index) {

  @if (item.items) {
  <button mat-menu-item (click)='navigate(item); $event.stopPropagation()'>
    <span>{{item.label}}</span>
    <span class="spacer"></span>
    <mat-icon>navigate_next</mat-icon>
  </button>
  }

  @if (item.url) {
  <a mat-menu-item [href]="item.url | safe:'url'" target="_blank"> {{ item.label }}</a>
  }

  }
  }

</mat-menu>

<a class="home-link">
  <img class="desktop" src="https://cdn.gsu.edu/img/gsuLogo.svg" alt="" />
  <img class="mobile" src="./assets/images/GSU_logo_mark_reverse.svg" alt="" />
</a>
<div class="push"></div>

@if (user && preferredName) {
<div class="animated fadeIn account-links">
  <!-- <button mat-icon-button><mat-icon>settings</mat-icon></button> -->
  <button mat-icon-button [matMenuTriggerFor]="logoutMenu">
    <mat-icon>account_circle</mat-icon>
  </button>
  <span class="un">{{preferredName}}</span>
</div>
}

<mat-menu #logoutMenu="matMenu" yPosition="below">
  <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Sign Out</button>
</mat-menu>