<div class="flx spread">
  <h3>Waivers</h3>
  <a [href]="titleLink" target="_blank">Details<i class="material-icons">open_in_new</i></a>
</div>

<ul class="items">
  @for (term of terms; track $index) {

  @if (0 < term.waivers.length) { <li>
    <h4>{{ term.semesterName }}</h4>
    <ul class="items">
      @for (waiver of term.waivers; track $index) {
      <li>
        <div class="flx spread indent">
          <div>{{ waiver.desc }}</div>
          <app-popover-link iconName="info" label="Details">
            <div class="bill-waiver-details-popover">
              <div class="flx spread">
                <h4>Award Date:</h4>
                <div>
                  {{ waiver ? waiver.date : waiver }}
                </div>
              </div>
              <div class="flx spread">
                <h4>Added By:</h4>
                <div>
                  {{ waiver ? waiver.addedBy : waiver }}
                </div>
              </div>
            </div>
          </app-popover-link>
        </div>
      </li>
      }
    </ul>
    </li>
    }

    }
</ul>