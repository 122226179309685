@if (gpaLevels) {
<div>
  <h3>Standing & GPA</h3>
  <hr class="half clear" />

  <div class="flx spread">
    <h4>Academic Standing</h4>
    <div>{{ enrollmentAdvice.academicStanding }}</div>
  </div>
  <hr class="half clear" />

  <ul class="items">
    @for (gpaLevel of gpaLevels; track $index) {
    <li>
      <h3>{{ gpaLevel.levelName }}</h3>
      <div class="flx spread indent">
        <h4>Institutional:</h4>
        <div>{{ gpaLevel.institutionalGPA }}</div>
        <app-popover-link iconName="info" label="Details">
          <div class="flx spread">
            <h4>Hours Attempted:</h4>
            <div>{{ getGpaDetails('institutional', gpaLevel).hoursAttempted }}</div>
          </div>
          <div class="flx spread">
            <h4>Hours Earned:</h4>
            <div>{{ getGpaDetails('institutional', gpaLevel).hoursEarned }}</div>
          </div>
        </app-popover-link>
      </div>
      <div class="flx spread indent">
        <h4>Overall:</h4>
        <div>{{ gpaLevel.overallGPA }}</div>
        <app-popover-link iconName="info" label="Details">
          <div class="flx spread">
            <h4>Hours Attempted:</h4>
            <div>{{ getGpaDetails('overall', gpaLevel).hoursAttempted }}</div>
          </div>
          <div class="flx spread">
            <h4>Hours Earned:</h4>
            <div>{{ getGpaDetails('overall', gpaLevel).hoursEarned }}</div>
          </div>
        </app-popover-link>
      </div>
    </li>
    }
  </ul>

  @if (enrollmentAdvice.hopeOverallGPA) {
  <ul class="items">
    <li>
      <h3>HOPE GPA</h3>
      <div class="flx spread indent">
        <h4>GPA:</h4>
        <div>{{ enrollmentAdvice.hopeOverallGPA }}</div>
        <div>&nbsp;</div>
      </div>
    </li>
  </ul>
  }

  <h3>Withdrawal History</h3>
  <div class="flx spread indent">
    <h4>Total Taken:</h4>
    <div>{{ enrollmentAdvice.withdrawalsTaken }}</div>

    @if (withdrawalDetailsUri) {
    <a [href]="withdrawalDetailsUri.url" target="_blank">
      {{ withdrawalDetailsUri.label }}<i class="material-icons">open_in_new</i>
    </a>
    }

  </div>
  <div class="flx spread indent">
    <h4>Total Remaining:</h4>
    <div>{{ enrollmentAdvice.withdrawalsRemaining }}</div>
    <div></div>
  </div>
</div>
}