import { Pipe, PipeTransform } from "@angular/core";
import { AdminEngineService } from "src/services/admin-engine.service";
import { EnvService } from "src/services/env.service";
import { OpenService } from "src/services/open.service";

@Pipe({
  standalone: true,
  name: "contexturl",
})
export class ContexturlPipe implements PipeTransform {
  private static data: { [key: string]: { URL: string } } = {};
  initialized: Promise<any>;

  constructor(
    private adminEngineService: AdminEngineService,
    private openService: OpenService
  ) {}

  initialize() {
    return (this.initialized = this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "urls")
      .then((r: any) => (ContexturlPipe.data = r)));
  }

  transform(
    context: { [key: string]: string | number },
    ...args: any[]
  ): string {
    let href: string = ContexturlPipe.data[args[0]].URL;
    href =
      href.indexOf("http") == 0
        ? href
        : this.openService.join(EnvService.GOSOLAR_ROOT, href);
    if (!href) return "";
    for (const key in context) {
      href = href.replace(`{{${key}}}`, context[key].toString());
    }
    return href;
  }
}
