import { Component, HostListener, Input } from "@angular/core";
import {
  Overlay,
  OverlayConfig,
  OverlayModule,
  OverlayRef,
} from "@angular/cdk/overlay";

@Component({
  standalone: true,
  selector: "app-popover-link",
  templateUrl: "./popover-link.component.html",
  styleUrls: ["./popover-link.component.scss"],
  imports: [OverlayModule],
})
export class PopoverLinkComponent {
  @Input() iconName?: string;
  @Input() url: string;
  @Input() label: string;

  isOpen = false;
  overlayRef: OverlayRef;
  constructor(public overlay: Overlay) {}

  openOverlay() {
    setTimeout(() => {
      this.isOpen = true;
      this.overlayRef = this.overlay.create(
        new OverlayConfig({
          hasBackdrop: false,
        })
      );
    }, 60);
  }

  @HostListener("window:click", ["$event.target"])
  onClick(target) {
    if (this.overlayRef && target !== this.overlayRef.hostElement) {
      this.close();
    }
  }

  close() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.isOpen = false;
    }
  }
}
