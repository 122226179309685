import { Injectable } from '@angular/core';
import { AdminEngineService } from './admin-engine.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  constructor(private adminEngineService: AdminEngineService) {}

  initialize() {
    return Promise.all([
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'checklists'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'notices'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'quick-links'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'fin-aid-years'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'downtime'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'student-resources'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'rendered-components'),
      this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'message-paragraphs')
    ]);
  }

}
