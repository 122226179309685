import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Component, Input, OnInit } from "@angular/core";
import { AdminEngineService } from "src/services/admin-engine.service";
import { EnvService } from "src/services/env.service";
import { Student } from "../../models/student-transform.model";
import { StudentService } from "../../services/student.service";
import { DatePipe } from "@angular/common";
@Component({
  standalone: true,
  selector: "banner",
  templateUrl: "banner.component.html",
  styleUrls: ["./banner.component.scss"],
  imports: [NgxSkeletonLoaderModule, DatePipe],
})
export class BannerComponent implements OnInit {
  @Input() student: Student;
  @Input() preferredName: string;
  @Input() showRefresh : boolean;
  knowledgeBaseUri: string;
  welcomeString: string;

  constructor(
    private studentService: StudentService,
    private adminEngineService: AdminEngineService
  ) {}

  ngOnInit() {
    const time = new Date().getHours();
    const name = this.preferredName;
    this.welcomeString =
      time < 12
        ? `Good Morning ${name}!`
        : time < 17
        ? `Good Afternoon ${name}!`
        : `Good Evening ${name}!`;
    this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "urls")
      .then((urls: any) => (this.knowledgeBaseUri = urls.PANTHER_ANSWERS.URL));
  }

  refreshStudentData() {
    this.studentService
      .refreshDashboardData()
      .then(() => window.location.reload());
  }
}
