import { MatSelectModule } from "@angular/material/select";
import { Component, Input, OnInit } from "@angular/core";
import { MatOption } from "@angular/material/core";

@Component({
  standalone: true,
  selector: "app-select-role",
  templateUrl: "./select-role.component.html",
  styleUrls: ["./select-role.component.scss"],
  imports: [MatOption, MatSelectModule],
})
export class SelectRoleComponent implements OnInit {
  @Input() data: any;
  constructor() {}

  ngOnInit(): void {}

  setUserRole($event) {
    this.data.persona = $event.value;
    this.data.dlg.close();
  }
}
