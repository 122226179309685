import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { StudentResourceService } from "../../../services/student-resource.service";
import { ErrorService } from "../../../services/error.service";
import {
  EnrollmentAdvice,
  DegreeMajor,
} from "../../../models/student-transform.model";

@Component({
  standalone: true,
  selector: "degrees-and-major-pathways",
  templateUrl: "degrees-and-major-pathways.component.html",
})
export class DegreesAndMajorPathwaysComponent implements OnChanges {
  private className = "DegreesAndMajorPathwaysComponent";
  @Input() enrollmentAdvice: EnrollmentAdvice;
  degreeOrMajorInfo: DegreeOrMajorInfo[];
  academicEvaluationUri: any;

  constructor(
    studentResourceService: StudentResourceService,
    private errorService: ErrorService
  ) {
    const fn: string = this.className + "#constructor"; // tslint:disable-line:no-unused-variable
    this.degreeOrMajorInfo = [];
    this.academicEvaluationUri = studentResourceService.getResource(
      "degrees-major-pathways",
      "academic-evaluation"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this only detects changes to the reference to the @Inputs, it will not detect changes to inner properties of the @Inputs
    const fn: string = "?>" + this.className + "#ngOnChanges"; // tslint:disable-line:no-unused-variable
    try {
      if (changes.enrollmentAdvice.currentValue !== undefined) {
        this.degreeOrMajorInfo = [];
        const degreeMajors: DegreeMajor[] = this.enrollmentAdvice
          .degreeMajors as DegreeMajor[];
        degreeMajors.forEach((degreeMajor: DegreeMajor) => {
          const degreeInfo: DegreeOrMajorInfo = {
            isDegree: true,
            value: degreeMajor.degree,
          };
          this.degreeOrMajorInfo.push(degreeInfo);

          const majorInfo: DegreeOrMajorInfo = {
            isDegree: false,
            value: degreeMajor.major,
          };
          this.degreeOrMajorInfo.push(majorInfo);
        });
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      e.message = fn + "/CATCH: Error in OnChanges -- " + e.message;
      console.error(e);
    }
  }
}

interface DegreeOrMajorInfo {
  isDegree: boolean;
  value: string;
}
