<h3>Academic Advisor</h3>

@if (studentAdvisors.length <= 0) { <div>
  @switch (levelCode) {
  @case ('UA') {
  <div [innerHtml]="'NOADVISOR_UA' | messageParagraphs"></div>
  }
  @case ('US') {
  <div [innerHtml]="'NOADVISOR_US' | messageParagraphs"></div>
  }
  @case ('GS') {
  <div [innerHtml]="'NOADVISOR_GS' | messageParagraphs"></div>
  }
  @case ('LW') {
  <div [innerHtml]="'NOADVISOR_LW' | messageParagraphs"></div>
  }
  }

  </div>
  }

  <ul class="items">
    @for (studentAdvisor of studentAdvisors; track $index) {
    <li>
      <div class="flx spread indent">
        <h4>{{ studentAdvisor.name }}</h4>
        <a href="mailto:{{ studentAdvisor.email }}">{{ studentAdvisor.email }} <i class="material-icons">email</i> </a>
      </div>
    </li>
    }
  </ul>