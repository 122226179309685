<h2 class="header animated fadeIn">
  GSU Quick Links
</h2>

<div class="panel animated fadeIn">

  @if (!displayParams) {
  <ngx-skeleton-loader [theme]="{ 'height.px': 130 }"></ngx-skeleton-loader>
  }

  @if (displayParams && displayParams.displayText) {
  <div>
    <div class="displayText">
      <p>{{ displayParams.textOne }}</p>
      <p><strong>{{ displayParams.textTwo }}</strong></p>
    </div>
  </div>
  }

  @if (!items) {
  <ngx-skeleton-loader count="5" appearance="line" class="animated fadeIn"
    [theme]="{ 'height.px': 56 }"></ngx-skeleton-loader>
  }

  @for (menu of items; track $index) {
  <mat-expansion-panel class="mat-elevation-z0 animated fadeIn" [expanded]="displayParams && displayParams.expanded">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ menu.label }}</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-nav-list>
      @for (item of menu.items; track $index) {
      <a [href]="item.url | safe:'url'" class="sidenav-link animated fadeIn" target="_blank" mat-list-item> {{
        item.label }}</a>
      }
    </mat-nav-list>
  </mat-expansion-panel>
  }
</div>