import { provideHttpClient } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ContexturlPipe } from "./pipes/contexturl.pipe";
import { MessageParagraphsPipe } from "./pipes/messageParagraphs.pipe";
 
export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    importProvidersFrom(BrowserAnimationsModule),
    ContexturlPipe,
    MessageParagraphsPipe,
  ],
};