import { BillWaiversComponent } from "./bill-waivers/bill-waivers.component";
import { BillRefundComponent } from "./bill-refund/bill-refund.component";
import { BillTuitionClassificationComponent } from "./bill-tuition-classification/bill-tuition-classification.component";
import { BillPaymentComponent } from "./bill-payment/bill-payment.component";
import { Component, Input } from "@angular/core";
import { EnvService } from "src/services/env.service";
import { Student } from "../../models/student-transform.model";
import { AdminEngineService } from "../../services/admin-engine.service";

@Component({
  standalone: true,
  selector: "bill",
  templateUrl: "bill.component.html",
  styleUrls: ["bill.component.scss"],
  imports: [
    BillPaymentComponent,
    BillTuitionClassificationComponent,
    BillRefundComponent,
    BillWaiversComponent,
  ],
})
export class BillComponent {
  @Input() student: Student;
  showAlert: boolean;
  sponsorAuthorizationUrl: string;
  waiverDetailsUrl: string;

  constructor(private adminEngineService: AdminEngineService) {
    this.showAlert = false;
    this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "urls")
      .then((urls: any) => {
        this.sponsorAuthorizationUrl = urls.BILL_SPONSORED_BILLING.URL;
        this.waiverDetailsUrl = urls.BILL_WAIVER_DETAILS.URL;
      });
  }
}
