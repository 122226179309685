<span class="icon-link" (click)="openOverlay()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  {{ label }}

  @if (iconName) {
  <i class="material-icons">{{ iconName }}</i>
  }

</span>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
  <div class="popover">
    <ng-content></ng-content>
  </div>
</ng-template>