<h2>My Registration</h2>
<div class="panel">

  <div style="display: flex; justify-content: center;">

    @if (academicEvaluationUri) {
    <a [href]="academicEvaluationUri.url" target="_blank" class="dont-wrap">{{academicEvaluationUri.label}}<i
        class="material-icons">open_in_new</i></a>
    }

    <span style="padding: 0px 1rem 0px 1rem">|</span>

    @if (academicEvaluationInfoUri) {
    <a [href]="academicEvaluationInfoUri.url" target="_blank" class="dont-wrap">
      {{academicEvaluationInfoUri.label}} <i class="material-icons">open_in_new</i>
    </a>
    }

  </div>

  <hr class="clear half">

  @if (hasHolds) {
  <div class="flx spread">
    <dynamic-texts [texts]="'MY_REGISTRATION_MESSAGE_PARAGRAPHS' | messageParagraphs" class="grow" class="red-font">
    </dynamic-texts>

    @if (actionRequiredUri) {
    <a [href]="actionRequiredUri.url" target="_blank" class="shrink">{{actionRequiredUri.label}}<i
        class="material-icons">open_in_new</i></a>
    }

  </div>
  }

  @if (enrollmentAdvice && enrollmentAdvice.termList) {
  <ul class="items">
    @for (term of enrollmentAdvice.termList; track $index) {

    @if (term.visible) {
    <li>
      <registration-term [enrollmentTerm]="term" [hasHolds]="hasHolds"></registration-term>
    </li>
    }

    }
  </ul>
  }

  @if (addDropResource && !hasHolds) {
  <button (click)="addDropClasses()" class="btn full">{{addDropResource.label}}</button>
  }

  <hr>
  <gpas [enrollmentAdvice]="enrollmentAdvice"></gpas>
  <hr>

  @if (showPathways) {
  <degrees-and-major-pathways [enrollmentAdvice]="enrollmentAdvice"></degrees-and-major-pathways>
  }

  <curriculum [enrollmentAdvice]="enrollmentAdvice"></curriculum>
  <hr>
  <student-advisor [enrollmentAdvice]="enrollmentAdvice"></student-advisor>
  <hr>

  @if (enrollmentAdvice.levelCode === 'GS' || enrollmentAdvice.levelCode === 'LW') {
  <dynamic-texts [texts]="'GRADUATION_ELIGIBILITY_MESSAGE_PARAGRAPHS' | messageParagraphs"></dynamic-texts>
  }

  @if (evaluateGradButton()) {
  <button class="btn full" (click)="applyToGraduate()">Apply to Graduate</button>
  }

</div>