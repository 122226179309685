import { MatListModule } from "@angular/material/list";
import { SafePipe } from "./../../pipes/safe.pipe";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AdminEngineService } from "../../services/admin-engine.service";
import { ApiService } from "../../services/api.service";
import { EnvService } from "../../services/env.service";
import { visible } from "../../services/helpers";

@Component({
  standalone: true,
  selector: "quick-links",
  templateUrl: "./quick-links.component.html",
  styleUrls: ["./quick-links.component.scss"],
  imports: [
    NgxSkeletonLoaderModule,
    MatExpansionModule,
    SafePipe,
    MatListModule,
  ],
})
export class QuickLinksComponent implements OnChanges, OnInit {
  @Input() data;
  params: any[];
  displayParams: any = {};
  panelOpenState = false;
  items: any = null;


  constructor(
    private apiService: ApiService,
    private adminEngineService: AdminEngineService
  ) { }

  async ngOnInit() {
    await this.fetchQuick_Links();
  }
  async ngOnChanges() {
    await this.fetchQuickLinks();
    this.displayParams = this.params.find((i) => visible(this.data, i));
  }

  async fetchQuickLinks() {
    try {
      const postBody = { role: this.data.persona.code };
      const res: any = await this.apiService.postAuth(EnvService.BANNER_URL + "/quickLinks", {}, postBody);
      this.items = JSON.parse((res).quickLinks);
    } catch (error) {
      this.items = [];
    }
  }

  async fetchQuick_Links() {
    try {
      const r = await this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, "quick-links");
      this.params = <any>r;
      this.displayParams = this.params.find((i) => visible(this.data, i));
    } catch (error) {
      this.params = void 0;
    }
  }

  display(data, item) {
    return visible(data, item);
  }
}
