import { JSONPath } from "jsonpath-plus";

const operators = {
  '>': (a, b) => a > b,
  '<': (a, b) => a < b,
  '=': (a, b) => a == b,
  '!=': (a, b) => a != b,
  'is in': (a, b, targetList) => targetList?.includes(b),
  'is': (a, b) => a == b,
  'is not': (a, b) => a != b,
  'found in': (a, b) => a.includes(b),
  'undefined': (a, b) => a == b
};

function inDateRange(item) {
  if (!item.from || (item.from && !item.from.date)) {
    return false;
  } else {
    const now = new Date(Date.now()).valueOf();
    const from = toJSVal(item.from.date, item.from.time);
    const to = item.to.date ? toJSVal(item.to.date, item.to.time) : undefined;
    return (from < now && now < to) || (from < now && !to);
  }
}

function toJSVal(date: string, time: string): number {
  if (!time) time = '00:00 AM';
  const day = date.split('T')[0];
  let hour = parseInt(time.split(':')[0]);
  const minute = parseInt(time.split(':')[1].split(' ')[0]);
  const ampm = time.split(' ')[1]?.trim();
  if (ampm == 'PM' && hour < 12) hour += 12;
  const tzOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(day + 'T' + (hour < 10 ? '0' + hour.toString() : hour) + ':' + (minute < 10 ? '0' + minute : minute) + ':00.000Z').valueOf() + tzOffset;
}

function compareKVIn(obj: any, item) {
  let out = false;
  let tl;
  if (obj && obj['targetedLists']) tl = obj['targetedLists'];
  for (let k in obj) {
    if (k === item.key && operators[item.verb](obj[k], item.value, tl)) return true;
    else if (typeof obj[k] === 'object') {
      if (Array.isArray(obj[k])) {
        for (const o of obj[k]) {
          out = out || compareKVIn(o, item);
        }
      } else out = out || compareKVIn(obj[k], item);
    }
  }
  return out;
}

function removeNullProps(k: string, v: any) {
  if(v === null)
    return undefined;
  return v;
}

function visible(data, item) {
  data = clone(data, removeNullProps);
    
  if (!item.target || item.target.length === 0) return true;
  let comp: boolean = true;

  for (const c of item.target) {
    if (c.key.startsWith('$')) {
      if (!data) return comp && false;
      const res = JSONPath({ path: c.key, json: data, resultType: 'value', wrap: false });
      if (res && typeof res[0] !== 'undefined') {
        comp = comp && operators[c.verb](res[0], c.value);
      } else {
        comp = comp && false;
      }
    } else if (c.key.includes('.')) {
      comp = comp && directValue(data, c);
    } else {
      comp = comp && compareKVIn(data, c);
    }
  }
  return comp;
}

function display(data, item) {
  return inDateRange(item) && visible(data, item);
}

function directValue(data, item): boolean {
  if (item.verb === 'is in') return operators[item.verb](data, item.value, data?.targetedLists || []);
  const keyPath = item.key.split('.');
  for (const key of keyPath) {
    if (typeof data[key] !== 'undefined') {
      data = data[key];
    } else {
      return false;
    }
  }
  return operators[item.verb](data, item.value, data?.targetedLists || []);
}

function getDeepKeys(obj: { [x: string]: any; }) {
  let keys = [];
  for (var key in obj) {
    keys.push(key);
    if (typeof obj[key] === "object") {
      const subkeys = getDeepKeys(obj[key]);
      keys = { ...keys, ...subkeys.map(subkey => `${key}.${subkey}`) };
    }
  }
  return keys;
}

function clone(data: any, replacer?: (key: string, value: any) => any) {
  return JSON.parse(JSON.stringify(data), replacer);
}

export { inDateRange, compareKVIn, display, visible, clone }
