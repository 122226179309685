import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { AdminEngine } from "../models/admin-engine.model";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AdminEngineService {
  private cache: { [key: string]: Promise<any> } = {};
  constructor(private apiService: ApiService) {}

  public getKey<T>(
    app: string,
    key: string,
    cached: boolean = true
  ): Promise<T> {
    const path: string = "/" + app + "/" + key;

    if (!cached || typeof this.cache[app + "/" + key] === "undefined") {
      // this.cache[app + '/' + key] = this.apiService.get<AdminEngine>(EnvService.ADMIN_ENGINE_CDN_URL + path)
      //   .then(result => result.valueBlob['S'] ? JSON.parse(result.valueBlob['S']) : result)
      //   .catch(() => this.apiService.get<AdminEngine>(EnvService.ADMIN_ENGINE_URL + path))
      //   .then(result => result.valueBlob ? JSON.parse(result.valueBlob) : result);

      this.cache[app + "/" + key] = this.apiService
        .get<AdminEngine>(EnvService.ADMIN_ENGINE_PUBLIC_URL + path)
        .then((result) =>
          result.valueBlob ? JSON.parse(result.valueBlob) : result
        );
    }
    return this.cache[app + "/" + key].then((v) =>
      JSON.parse(JSON.stringify(v))
    );
  }
}
