import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export type ErrorLevel = 'debug' | 'info' | 'warn' | 'error';

export type LogServiceData = {
  level: ErrorLevel;
  args: any[];
};

@Injectable({
  providedIn: "root"
})
export class LogService {
  logs: ReplaySubject<LogServiceData> = new ReplaySubject(100);
  constructor() { }

  debug(...args) {
    console.debug(...args);
    this.logs.next({ level: 'debug', args });
  }

  info(...args) {
    console.info(...args);
    this.logs.next({ level: 'info', args });
  }

  warn(...args) {
    console.warn(...args);
    this.logs.next({ level: 'warn', args });
  }

  error(...args) {
    console.error(...args);
    this.logs.next({ level: 'error', args });
  }
};
