import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import QueryString from 'querystring';

const console: Console = window.console; // overriding global console -- declare outside of export

@Injectable({
  providedIn : "root"
})
export class OpenService {
  private className = 'OpenService';

  reloading = false;

  constructor(
    authService: AuthService,
  ) { }

  public openUrl(url: string, query?: any): void {
    if (query) { url = this.addQuery(url, query); }
    if (!url.startsWith('https://')) { console.warn(': Opening INSECURE URL ', url); } else { }
    window.open(url, '_blank', 'toolbar=yes,location=yes,status=yes,menubar=yes,scrollbars=yes,fullscreen=no');
  }

  public addQuery(url: string, query: any): string {
    const queryString: string = QueryString.stringify(query);
    if ('' !== queryString) {
      url += '?' + queryString;
    }
    return url;
  }

  public reload(): void {
    const fn: string = this.className + '#reload'; // tslint:disable-line:no-unused-variable
    // console.warn(fn + ': RELOAD ' + this.loginService.modeService.url);
    // window.location.href = this.loginService.modeService.url;
    window.location.href = '';
    window.location.reload();
    this.reloading = true;
  }

  public join(baseURL: string, path: string) { // TODO: accept arbitrarily many parameters (as in RCB-Forms)
    let url: string = baseURL;
    if ('/' !== url.slice(-1) && '/' !== path[0]) { url += '/'; }
    url += path;
    return url;
  }
}
