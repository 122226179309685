import { SemesterName } from "./../../../pipes/semesterName.pipe";
import { DynamicTextsComponent } from "./../../dynamic-texts/dynamic-texts.component";
import { DollarsPipe } from "./../../../pipes/dollars.pipe";
import { PopoverLinkComponent } from "./../../popover-link/popover-link.component";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { AdminEngineService } from "../../../services/admin-engine.service";
import { ErrorService } from "../../../services/error.service";
import { OpenService } from "../../../services/open.service";
import { StudentResourceService } from "../../../services/student-resource.service";
import { AdminEngine } from "../../../models/admin-engine.model";
import { Student } from "../../../models/student-transform.model";
import { SemesterTerm } from "../../../models/student-transform.model";
import { EnvService } from "src/services/env.service";

@Component({
  selector: "gsu-bill-payment",
  standalone: true,
  templateUrl: "bill-payment.component.html",
  imports: [
    PopoverLinkComponent,
    DollarsPipe,
    DynamicTextsComponent,
    SemesterName,
  ],
})
export class BillPaymentComponent implements OnChanges {
  private className = "BillPaymentComponent";

  @Input() student: Student;
  aMessage: string[];
  aMessageShow: boolean;
  viewPayAccountUrl: string | undefined;
  internationalWirePaymentUrl: string;
  internationalWirePaymentQuery: any;
  paymentURL: string;
  refundsURL: string;

  constructor(
    private adminEngineService: AdminEngineService,
    private errorService: ErrorService,
    private openService: OpenService,
    private studentResourceService: StudentResourceService
  ) {
    const fn: string = this.className + "#constructor"; // tslint:disable-line:no-unused-variable
    this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "urls")
      .then((urls: any) => {
        this.internationalWirePaymentQuery = urls.BILL_INTERNATIONAL.QUERIES;
        this.internationalWirePaymentUrl = urls.BILL_INTERNATIONAL.URL;
        this.paymentURL = urls.BILL_PAYMENT_OPTIONS.URL;
        this.refundsURL = urls.BILL_REFUND_OPTIONS.URL;
        if (this.student) {
          this.ngOnChanges(undefined);
        }
      });

    const r = this.studentResourceService.getResource(
      "my-bill",
      "view-pay-account"
    );
    if (r) {
      this.viewPayAccountUrl = r.url;
    }
  }

  ngOnChanges(changes: SimpleChanges | undefined): void {
    // this only detects changes to the reference to the @Inputs, it will not detect changes to inner properties of the @Inputs
    const fn: string = "?>" + this.className + "#ngOnChanges"; // tslint:disable-line:no-unused-variable
    try {
      this.evaluateShowMessages();
      if (this.internationalWirePaymentQuery && this.student) {
        this.internationalWirePaymentQuery.student_id = this.student?.studentId;
        this.internationalWirePaymentQuery.student_first_name =
          this.student?.studentFirstName;
        this.internationalWirePaymentQuery.student_last_name =
          this.student?.studentLastName;
        this.internationalWirePaymentQuery.amount =
          this.student?.studentAccounts?.totalAmountDue * 100;
        this.internationalWirePaymentQuery.max_amount =
          this.student?.studentAccounts?.totalAmountDue * 100;
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      e.message = fn + "/CATCH: Error in OnChanges -- " + e.message;
      console.error(e);
    }
  }

  evaluateShowMessages(): void {
    const fn: string = this.className + "#evaluateShowMessages"; // tslint:disable-line:no-unused-variable
    this.aMessageShow = false;
    if (
      this.student?.studentAccounts?.semesterTerms.some(
        (term: SemesterTerm) => term.aMessageCode === "Y"
      )
    ) {
      this.adminEngineService
        .getKey(EnvService.DASHBOARD_APP_ID, "a-and-f-messages")
        .then((messages: AdminEngine) => {
          this.aMessageShow = true;
          this.aMessage = [];
          const body: any = messages;
          this.aMessage[0] = body.aMessage;
        })
        .catch((err: Error) => {
          this.errorService.logError(err);
          this.errorService.showLoadingError(
            "Failed to retrieve aMessage data -- " + err.message
          );
          this.aMessage = ["ERROR: Failed to retrieve aMessage data"];
        });
    }
  }

  viewPayAccount() {
    const fn: string = "?>" + this.className + "#viewPayAccount"; // tslint:disable-line:no-unused-variable
    if (this.viewPayAccountUrl) {
      this.openService.openUrl(this.viewPayAccountUrl);
    } else {
      throw new Error(
        fn + ": Cannot viewPayAccount when viewPayAccountURL is undefined"
      );
    }
  }

  getWirePaymentQuery(charge: any): void {
    this.internationalWirePaymentQuery.amount = charge * 100;
    this.internationalWirePaymentQuery.max_amount = charge * 100;
    this.internationalWirePayment();
  }

  internationalWirePayment() {
    this.openService.openUrl(
      this.internationalWirePaymentUrl,
      this.internationalWirePaymentQuery
    );
  }
}
