import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
  StudentAdvisor,
  EnrollmentAdvice,
} from "../../../models/student-transform.model";
import { MessageParagraphsPipe } from "../../../pipes/messageParagraphs.pipe";

@Component({
  standalone: true,
  selector: "student-advisor",
  templateUrl: "student-advisor.component.html",
  imports: [MessageParagraphsPipe],
})
export class StudentAdvisorComponent implements OnChanges {
  @Input() enrollmentAdvice: EnrollmentAdvice;
  studentAdvisors: StudentAdvisor[] = [];
  levelCode = "";

  ngOnChanges(changes: SimpleChanges): void {
    this.levelCode = this.enrollmentAdvice.levelCode;
    this.studentAdvisors = this.enrollmentAdvice.studentAdvisors;
  }
}
