import { Injectable, Inject } from '@angular/core';
import { EnvService } from './env.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AdminEngineService } from './admin-engine.service';
import { ApiService } from './api.service';
import { LogService } from './log.service';
import { MatDialog } from '@angular/material/dialog';
import { ReloadComponent } from 'src/components/reload/reload.component';

@Injectable({
  providedIn : "root"
})
export class ErrorService {
  envName = '';
  constructor(
    public dialog: MatDialog,
    private apiService: ApiService,
    private adminEngineService: AdminEngineService,
    private deviceService: DeviceDetectorService,
    logService: LogService
  ) {
    if (EnvService.API_URL.includes('-dev')) this.envName = 'DEV';
    if (EnvService.API_URL.includes('-qa')) this.envName = 'QA';
    if (EnvService.API_URL.indexOf('-') === -1) this.envName = 'PROD';
    logService.logs.subscribe(e => {
      if (e.level === 'error') {
        const err = typeof e.args[0] === 'string' ? e.args[0] : JSON.stringify(e.args[0]);
        this.handleError(new Error(err));
      }
    });
  }

  logError(err: Error) {
    if (!(err instanceof Error)) { err = new Error(err); }
    const deviceInfo = this.deviceService.getDeviceInfo() as any;
    deviceInfo.message = err.message || err;
    deviceInfo.app = 'StudentDashboard-' + this.envName;
    deviceInfo.url = window.location.href;
    deviceInfo.eventTime = Date.now();
    this.apiService
      .post(ApiService.joinUrl(EnvService.GSU_DATA_SERVICE_URL, '/logevent'), {}, deviceInfo)
      .catch((e: Error) => console.error(e));
  }

  showLoadingError(message?: string): void {
    this.showError('Loading Error', message || 'There was an error loading the page.  Please reload the app.');
  }

  showDataServiceError(): void {
    this.showConfigurableError(
      'Data Service Error',
      'There was an error communicating with the data service. Please try again. If problems persist, please contact a system administrator.',
      'data-service-error-message'
    );
  }

  showAuthenticationError(): void {
    this.showConfigurableError(
      'Authentication Error',
      'There was an authentication error. Please try logging back in. If problems persist, please contact a system administrator.',
      'authentication-error-message'
    );
  }

  private showConfigurableError(title: string, defaultMessage: string, key: string): void {
    this.getErrorMessageParagraph(key).then((errorMessageParagraph) => {
      if (typeof errorMessageParagraph === 'string') {
        this.showError(title, errorMessageParagraph || defaultMessage);
      } else {
        this.showError(title, defaultMessage);
      }
    }).catch((err) => {
      console.error(err);
      this.showError(title, defaultMessage);
    });
  }

  private getErrorMessageParagraph(key: string): Promise<string> {
    return this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'error-paragraphs')
      .then((errorMessageParagraphs: { [key: string]: string }) => errorMessageParagraphs[key]);
  }

  public handleError(err: Error) {
    console.error(err);
    this.logError(err);
  }

  async showError(title: string, message: string) {
    this.dialog.open(ReloadComponent, {
      width: '600px',
      data: { title, message }
    });
  }

  showErrorPassThru(error: Error): void {
    this.showError('Whoops! Something went wrong.', error.message);
  }
}
