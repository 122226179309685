<h2>My Bill @if (showAlert) {<i class="alert-icon-right material-icons">error</i> }</h2>

@if (student) {
<div class="panel">
  <gsu-bill-payment [student]="student"></gsu-bill-payment>
  <hr>
  <gsu-bill-tuition-classification [student]="student"></gsu-bill-tuition-classification>
  <hr>
  <gsu-bill-refund [studentAccounts]="student.studentAccounts"></gsu-bill-refund>
  <hr>
  <div>
    <div class="flx spread">
      <h3>Sponsor Authorization / Payment</h3>
      <a [href]="sponsorAuthorizationUrl" target="_blank">Details<i class="material-icons">open_in_new</i></a>
    </div>

    @if (student.studentAccounts) {
    <ul class="items">
      @for (term of student.studentAccounts.semesterTerms; track $index) {

      @if (0 < term.sponsors.length) { <li>
        <h4>{{ term.semesterName }}</h4>
        <ul class="items">
          @for (sponsor of term.sponsors; track $index) {
          <li class="flx spread indent">
            <div></div>
            <div>{{ sponsor.desc }}</div>
          </li>
          }
        </ul>
        </li>
        }

        }
    </ul>
    }@else {
    <div> Student Account information temporarily unavailable </div>
    }

  </div>
  <hr>

  @if (student.studentAccounts) {
  <gsu-bill-waivers [titleLink]="waiverDetailsUrl" [terms]="student.studentAccounts.semesterTerms"></gsu-bill-waivers>
  }

  <br>
</div>

}