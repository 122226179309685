import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test-environment',
  standalone: true,
  imports: [
    FormsModule,
    MatCheckboxModule,
    MatButtonModule],
  templateUrl: './test-environment.component.html',
  styleUrl: './test-environment.component.scss'
})
export class TestEnvironmentComponent {
  storageKey = 'hide-paws-test-environment-warning';
  doNotShow: boolean = false;
  show: boolean = !environment.production;
  constructor() {
    this.show = this.show && !localStorage.getItem(this.storageKey);
  }
  hide() {
    if (this.doNotShow) {
      localStorage.setItem(this.storageKey, '1');
    }
    this.show = false;
  }
}
