import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { SafeHtml, DomSanitizer } from "@angular/platform-browser";

@Component({
  standalone: true,
  selector: "dynamic-texts",
  templateUrl: "dynamic-texts.component.html",
})
export class DynamicTextsComponent implements OnInit, OnChanges {
  content: SafeHtml[];
  @Input() texts: string[] | SafeHtml;
  @Input("custom-class") customClass: string;

  constructor(private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    if ("length" in this.texts)
      this.content = this.texts.map((v) =>
        this.domSanitizer.bypassSecurityTrustHtml(v)
      );
    else {
      this.content = [this.texts];
    }
  }
}
