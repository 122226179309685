import { Injectable } from '@angular/core';
import { AdminEngineService } from './admin-engine.service';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class RenderedComponentsService {
  private static data: { [key: string]: boolean };

  constructor(private adminEngineService: AdminEngineService) {}

  initialize() {
    return this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'rendered-components')
      .then((resp: { [key: string]: boolean }) => RenderedComponentsService.data = resp);
  }

  getComponent(key: string) {
    return !!RenderedComponentsService.data[key];
  }
}
