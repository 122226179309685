import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    iframe: HTMLIFrameElement;
    events: Subject<User | void> = new Subject();
    initialized = false;
    config = environment.loginConfig;
    constructor() {
        window.addEventListener('message', async ev => {
            if (ev.data.event == 'USER_FOUND' || ev.data.event == 'USER_NOTFOUND') {
                this.events.next(ev.data);
            }
            if (ev.data.event == 'ORIGIN_REQUEST' && ev.origin === environment.cdnDomain) {
                this.iframe.contentWindow.postMessage({
                    event: 'ORIGIN_RESPONSE',
                    origin: window.location.origin,
                    uuid: ev.data.uuid
                }, environment.cdnDomain);
            }
        });

        this.iframe = document.createElement('iframe');
        this.iframe.style.position = 'fixed';
        this.iframe.style.top = '-100%';
        this.iframe.style.left = '-100%';
        this.iframe.style.width = '0';
        this.iframe.style.opacity = '0';
        this.iframe.style.height = '0';
        this.iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-forms');
        window.document.body.appendChild(this.iframe);
    }

    recover() {
        if (this.initialized)
            return;
        this.initialized = true;
        if (this.config.silent) {
            this.iframe.src = this.config.silent;
        }
    }

    nextUpdateEvent() {
        return NextObserved(this.events);
    }
}

function NextObserved<T>(s: Subject<T>): Promise<T> {
    let resolve: (value: T | PromiseLike<T>) => void;
    const p = new Promise<T>((r) => resolve = r);
    const sub = s.subscribe(v => {
        sub.unsubscribe();
        resolve(v);
    });
    return p;
}

export interface LoginServiceConfig {
    embedded?: string;
    redirect?: string;
    silent?: string;
    popout?: string;
    logout?: string;
};

export class User {
    /** The id_token returned from the OIDC provider */
    id_token: string;
    /** The session state value returned from the OIDC provider (opaque) */
    session_state?: string;
    /** The access token returned from the OIDC provider. */
    access_token: string;
    /** Refresh token returned from the OIDC provider (if requested) */
    refresh_token?: string;
    /** The token_type returned from the OIDC provider */
    token_type: string;
    /** The scope returned from the OIDC provider */
    scope: string;
    /** The claims represented by a combination of the id_token and the user info endpoint */
    profile: Profile;
    /** The expires at returned from the OIDC provider */
    expires_at: number;
    /** The custom state transferred in the last signin */
    state: any;
    /** Calculated number of seconds the access token has remaining */
    readonly expires_in: number;
    /** Calculated value indicating if the access token is expired */
    readonly expired: boolean;
    /** Array representing the parsed values from the scope */
    readonly scopes: string[];
}

export type Profile = IDTokenClaims & ProfileStandardClaims;

interface IDTokenClaims {
    /** Issuer Identifier */
    iss: string;
    /** Subject identifier */
    sub: string;
    /** Audience(s): client_id ... */
    aud: string;
    /** Expiration time */
    exp: number;
    /** Issued at */
    iat: number;
    /** Time when the End-User authentication occurred */
    auth_time?: number;
    /** Time when the End-User authentication occurred */
    nonce?: number;
    /** Access Token hash value */
    at_hash?: string;
    /** Authentication Context Class Reference */
    acr?: string;
    /** Authentication Methods References */
    amr?: string[];
    /** Authorized Party - the party to which the ID Token was issued */
    azp?: string;
    /** Session ID - String identifier for a Session */
    sid?: string;
    /** Other custom claims */
    [claimKey: string]: any;
}

interface ProfileStandardClaims {
    /** End-User's full name */
    name?: string;
    /** Given name(s) or first name(s) of the End-User */
    given_name?: string;
    /** Surname(s) or last name(s) of the End-User */
    family_name?: string;
    /** Middle name(s) of the End-User */
    middle_name?: string;
    /** Casual name of the End-User that may or may not be the same as the given_name. */
    nickname?: string;
    /** Shorthand name that the End-User wishes to be referred to at the RP, such as janedoe or j.doe. */
    preferred_username?: string;
    /** URL of the End-User's profile page */
    profile?: string;
    /** URL of the End-User's profile picture */
    picture?: string;
    /** URL of the End-User's Web page or blog */
    website?: string;
    /** End-User's preferred e-mail address */
    email?: string;
    /** True if the End-User's e-mail address has been verified; otherwise false. */
    email_verified?: boolean;
    /** End-User's gender. Values defined by this specification are female and male. */
    gender?: string;
    /** End-User's birthday, represented as an ISO 8601:2004 [ISO8601‑2004] YYYY-MM-DD format */
    birthdate?: string;
    /** String from zoneinfo [zoneinfo] time zone database representing the End-User's time zone. */
    zoneinfo?: string;
    /** End-User's locale, represented as a BCP47 [RFC5646] language tag. */
    locale?: string;
    /** End-User's preferred telephone number. */
    phone_number?: string;
    /** True if the End-User's phone number has been verified; otherwise false. */
    phone_number_verified?: boolean;
    /** object 	End-User's preferred address in JSON [RFC4627] */
    address?: OidcAddress;
    /** Time the End-User's information was last updated. */
    updated_at?: number;
}

interface OidcAddress {
    /** Full mailing address, formatted for display or use on a mailing label */
    formatted?: string;
    /** Full street address component, which MAY include house number, street name, Post Office Box, and multi-line extended street address information */
    street_address?: string;
    /** City or locality component */
    locality?: string;
    /** State, province, prefecture, or region component */
    region?: string;
    /** Zip code or postal code component */
    postal_code?: string;
    /** Country name component */
    country?: string;
}
