<div>
  <div class="flx spread">
    <h3>{{enrollmentTerm.termDescription}}</h3>

    @if (viewCourseScheduleResource) {
    <a [href]="viewCourseScheduleResource.url" target="_blank">{{viewCourseScheduleResource.label}}<i
        class="material-icons">open_in_new</i></a>
    }@else {
    <span></span>
    }

  </div>

  @if (registrationResource && !hasHolds) {
  <div class="flx spread">
    <p>Eligible to Register</p>
    <a [href]="registrationResource.url" target="_blank">Details<i class="material-icons">open_in_new</i></a>
  </div>
  }

</div>