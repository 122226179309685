@if (!displayPage) {
<ngx-skeleton-loader class="animated fadeIn" count="1" [theme]="{ 'height.px': 115 }"></ngx-skeleton-loader>
}

@if (diagMode) {
<div class="panel">
  <button class="btn" (click)="exportBanner()">Export Banner Data</button>
  <button class="btn" (click)="exportStudent()">Export Student Object</button>
  <textarea [(ngModel)]="importData" name="importData" placeholder="Data Object"></textarea>
  <button class="btn" (click)="importBanner()">Import as Banner Data</button>
  <button class="btn" (click)="importStudent()">Import as Student Object</button>
</div>
}

@if (displayPage) {
<div class="panel">
  <form (ngSubmit)="getStudent()">

    @if (message.length > 0) {
    <ul class="items">
      @for (m of message; track $index) {
      <li class="red-font">{{m}}</li>
      }
    </ul>
    }

    <div class="flx spread searchbox">
      <input type="text" [(ngModel)]="query" name="query" placeholder="Search either Campus ID or Panther Number" />
      <div>
        <button type="submit" class="btn">Search</button>
      </div>
    </div>
  </form>
</div>
}

@if (student) {
<div id="studentHeader" class="panel">
  <h3>Viewing As {{student.studentFirstName}} {{student.studentLastName}} ({{student.studentCampusId}} |
    {{student.studentId}})</h3>
</div>
}