import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingKeys: { [key: string]: any } = {};
  private isLoading = false;
  public loadingChanges: Subject<boolean> = new Subject<boolean>();
  public message?: string;

  constructor() { }

  start(key: string, timeout?: number): void {
    if (typeof timeout !== 'undefined') {
      clearTimeout(this.loadingKeys[key]);
    }
    this.loadingKeys[key] = timeout ? setTimeout(() => { this.stop(key); }, timeout) : true;
    this.setLoading(true);
    this.message = key;
  }

  stop(key: string): void {
    clearTimeout(this.loadingKeys[key]);
    delete this.loadingKeys[key];
    const keys = Object.keys(this.loadingKeys);
    this.setLoading(keys.length > 0);
    if (this.isLoading) {
      this.message = keys[0];
    } else {
      delete this.message;
    }
  }

  clearTimer(key: string) {
    clearTimeout(this.loadingKeys[key]);
  }

  clearAll(): void {
    for (const key in this.loadingKeys) {
      this.clearTimer(key);
    }
  }

  stopAll() {
    for (const key in this.loadingKeys) {
      this.stop(key);
    }
  }
  private setLoading(bool: boolean) {
    if (bool === this.isLoading) return;
    this.isLoading = bool;
    this.loadingChanges.next(this.isLoading);
  }
}
