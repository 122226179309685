<div class="flx spread">
  <h3>Refund</h3>
  @if (refundOptionsUri) {
  <a [href]="refundOptionsUri.url" target="_blank">
    Refund Options<i class="material-icons">open_in_new</i>
  </a>
  }

</div>

@if (studentAccounts && studentAccounts.displayRefundMsg) {
<dynamic-texts [texts]="'MY_BILL_REFUND_MESSAGE' | messageParagraphs" custom-class="red-font"></dynamic-texts>
}

@if (studentAccounts && studentAccounts.refundLastAmount && studentAccounts.refundLastDate) {
<div class="flx spread indent">
  <h4>Last Refund Amount:</h4>
  <div>{{ studentAccounts.refundLastAmount | dollars }}</div>
  <app-popover-link iconName="info" label="Details">
    <div class="bill-refund-popover">Refund Post: {{ billRefundData.date }}</div>
  </app-popover-link>
</div>
}

@if (!studentAccounts || !studentAccounts.refundLastAmount || !studentAccounts.refundLastDate) {
<div>
  No recent refunds
</div>
}