export type ReferenceFunction<T> = (obj: any, prepared: any) => T;
export interface ReferenceConfig<T> {
  reference?: SingleOrArray<string>;
  default?: T;
  transform?: ReferenceFunction<T>;
  prepare?: (src: any) => { [key: string]: any };
  postTransform?: (src: T) => T;
}
export type Reference<T> = string | ReferenceConfig<T> | ReferenceFunction<T>;

export type ReferenceMap<T> = {
  [P in keyof T]: SingleOrArray<Reference<T[P]>>;
};
type ArrayElement<T> = T extends (infer U)[] ? U : T;
type SingleOrArray<T> = T | T[];

export function Transform<T>(src: SingleOrArray<any>, target: SingleOrArray<ReferenceMap<ArrayElement<T>>>): T {
  return TransformMap<T>(src, target, {});
}
function TransformMap<T>(src: SingleOrArray<any>, target: SingleOrArray<ReferenceMap<ArrayElement<T>>>, prepared: any): T {
  if (Array.isArray(target)) {
    if (Array.isArray(src)) {
      return <T><any>src.map((s: any) => TransformMap<ArrayElement<T>>(s, <any>target[0], prepared));
    }
    return <T>(<any>undefined);
  }
  const result: Partial<ArrayElement<T>> = {};
  for (const key in target) {
    result[key] = GetValue(target[key], src, prepared);
  }
  return <T>result;
}
function GetValue<T>(reference: SingleOrArray<Reference<T>>, src: any, prepared: any): T {
  if (Array.isArray(reference)) {
    for (let index = 0; index < reference.length; index++) {
      const r = GetValue<T>(reference[index], src, prepared);
      if (typeof r !== 'undefined') {
        return r;
      }
    }
    return <T>(<any>undefined);
  }
  let r;
  switch (typeof reference) {
    case 'object':
      r = TransformConfig<T>(<any>reference, src);
      break;
    case 'string':
      r = TraverseObjects<T>(<any>reference, src);
      break;
    case 'function':
      r = (<any>reference)(src, prepared);
      break;
    default:
      r = <T>(<any>undefined);
  }
  if (typeof r === 'string' && r.toLowerCase() === 'null') {
    return <T>(<any>undefined);
  }
  return r;
}
function TraverseObjects<T>(key: string, src: any): T {
  const paths = key[0] === '$' ? key.split('.').slice(1) : [key];
  let i = 0;
  let r = src[paths[i]];
  while (++i < paths.length && r) {
    const element = paths[i];
    if (!r[element]) {
      return r;
    }
    r = r[element];
  }
  return r;
}

function TransformConfig<T>(key: ReferenceConfig<T>, src: any): T {
  const ref = key.reference ? <T>GetValue(key.reference, src, {}) : null;
  const prepared = key.prepare ? key.prepare(ref) : {};
  const r: T | null = (key.transform && ref ? key.transform(<any><any>ref, prepared) : ref);
  const r2 = <T>(typeof r !== 'undefined' || r !== null ? r : key.default);
  return key.postTransform ? key.postTransform(r2) : r2;
}
