import { RenderedComponentsService } from "../../../services/rendered-components.service";
import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { StudentResourceService } from "../../../services/student-resource.service";
import {
  EnrollmentAdvice,
  DegreeMajor,
} from "../../../models/student-transform.model";

@Component({
  standalone: true,
  selector: "curriculum",
  templateUrl: "curriculum.component.html",
})
export class CurriculumComponent implements OnChanges {
  @Input() enrollmentAdvice: EnrollmentAdvice;
  public termInfo:  any[] = [];// DegreeMajor[] = [];
  public academicEvaluationUri: any;
  public degreePlanUri: any;
  public graduateDegreePlanUri: any;
  public showDegreePlan: boolean;

  constructor(
    studentResourceService: StudentResourceService,
    private renderedComponentsService: RenderedComponentsService
  ) {
    this.academicEvaluationUri = studentResourceService.getResource(
      "degrees-major-pathways",
      "academic-evaluation"
    );
    this.degreePlanUri = studentResourceService.getResource(
      "degrees-major-pathways",
      "degree-plan"
    );
    this.graduateDegreePlanUri = studentResourceService.getResource(
      "degrees-major-pathways",
      "degree-plan-graduate"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enrollmentAdvice.currentValue !== undefined) {
      this.termInfo = this.enrollmentAdvice.degreeMajors;
    }
    this.showDegreePlan = this.renderedComponentsService.getComponent(
      `degreePlanURI_${changes.enrollmentAdvice.currentValue.levelCode}`
    );
  }
}
