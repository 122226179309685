import { FinancialAidComponent } from "./../financial-aid/financial-aid.component";
import { BillComponent } from "./../bill/bill.component";
import { RegistrationComponent } from "./../registration/registration.component";
import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { FinancialAidModalComponent } from "../financial-aid-modal/financial-aid-modal.component";
import { RenderedComponentsService } from "../../services/rendered-components.service";
import {
  FinancialAidYear,
  FinancialAidRequirement,
  Student,
} from "../../models/student-transform.model";
import { MatDialog } from "@angular/material/dialog";

@Component({
  standalone: true,
  selector: "dashboard-component",
  templateUrl: "dashboard.component.html",
  styleUrls: ["dashboard.component.scss"],
  imports: [RegistrationComponent, BillComponent, FinancialAidComponent],
})
export class DashboardComponent implements OnChanges {
  @Input()
  student: Student;
  selectedAidYearId: string;
  selectedAidYear: FinancialAidYear | undefined;
  aidYears: ReadonlyArray<FinancialAidYear> = [];
  requiredDocs: ReadonlyArray<FinancialAidRequirement>;
  requiredActions: ReadonlyArray<FinancialAidRequirement>;
  showAlert = false;
  showModal = false;
  shown = false;

  constructor(
    public dialog: MatDialog,
    private renderedComponentsService: RenderedComponentsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes.student.currentValue !== undefined) {
        // Added to displayModal function because only checking for splash page right now.
        this.aidYears = this.student.financialAid.financialAidYears;

        if (this.aidYears.length > 0) {
          this.selectAidYearById(this.aidYears[0].id);
        }

        if (
          this.showModal === true &&
          this.shown === false &&
          this.renderedComponentsService.getComponent("splash-page")
        ) {
          this.displayModal();
        }
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      console.error(e);
    }
  }

  selectAidYearById(selectedAidYearId: any) {
    this.selectedAidYearId = selectedAidYearId;
    this.selectedAidYear = this.aidYears.find((a: any) => {
      return a.id === selectedAidYearId;
    });
    if (this.selectedAidYear) {
      this.requiredDocs = this.selectedAidYear.requirements.documents;
      this.requiredActions = this.selectedAidYear.requirements.actions;
    }

    if (this.selectedAidYear && this.selectedAidYear.aidCode === "Y") {
      this.showAlert = true;
      // this.showModal = true; Removed for customer requirement.
    }
    if (this.selectedAidYear && this.requiredDocs.length > 0) {
      this.showAlert = true;
      this.showModal = true;
    }
    if (this.requiredActions.length > 0) {
      this.showAlert = true;
      this.showModal = true;
    }
  }

  displayModal(selectedAidYear?: any) {
    let componentProps;
    if (selectedAidYear && selectedAidYear !== undefined) {
      componentProps = { student: this.student, aidYear: selectedAidYear };
    } else {
      componentProps = { student: this.student };
    }
    const profileModal = this.dialog.open(FinancialAidModalComponent, {
      width: "600px",
      data: componentProps,
    });
    this.shown = true;
    profileModal
      .afterClosed()
      .toPromise()
      .then(() => {
        this.shown = false;
        this.selectAidYearById(this.selectedAidYearId);
      });
  }
}
