import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Student } from "../../../models/student-transform.model";
import { LoadingService } from "../../../services/loading.service";
import { ErrorService } from "../../../services/error.service";
import { AdminEngineService } from "../../../services/admin-engine.service";
import { EnvService } from "src/services/env.service";

@Component({
  selector: "gsu-bill-tuition-classification",
  standalone: true,
  templateUrl: "bill-tuition-classification.component.html",
  styleUrls: ["bill-tuition-classification.component.scss"],
})
export class BillTuitionClassificationComponent implements OnChanges {
  @Input() student: Student;

  classificationDescription = "Evaluating";
  classificationURI: string;

  constructor(
    private adminEngineService: AdminEngineService,
    private errorService: ErrorService
  ) {
    this.adminEngineService
      .getKey(EnvService.DASHBOARD_APP_ID, "urls")
      .then(
        (urls: any) =>
          (this.classificationURI = urls.BILL_TUITION_CLASSIFICATION.URL)
      );
    this.tuitionEvaluator();
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes.student.currentValue !== undefined) this.tuitionEvaluator();
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      console.error(e);
    }
  }

  tuitionEvaluator(): void {
    if (this.student && this.student.enrollmentAdvice) {
      switch (this.student.enrollmentAdvice.tuitionClassification) {
        case null:
        case undefined:
          break;
        case "9":
        case "R":
          this.classificationDescription = "In State";
          break;
        case "Z":
          this.classificationDescription = "Out of Country";
          break;
        default:
          this.classificationDescription = "Out of State";
          break;
      }
    }
  }
}
