<header class="modal-header">
    <h1>{{ data.title}}</h1>
</header>
<section class="modal-body">
    <p>
        {{ data.message }}
    </p>
    <div>
        <button (click)="dismiss()" class="btn">Dismiss</button>
        <button (click)="reload()" class="btn">Reload</button>
    </div>
</section>