<ul class="items">
  @for (semesterCharge of student.studentAccounts.semesterCharges; track $index) {
  <li>
    <div class="flx spread">
      <h4>{{ semesterCharge.semesterName }}</h4>
      <div style="font-weight: bold; font-size: 22px;">{{ semesterCharge.totalAmountDue | dollars }}</div>
      <app-popover-link iconName="info" label="Details">
        <h4>
          {{ semesterCharge.semesterName }}
        </h4>
        <div class="flx spread">
          <div>Total Billed</div>
          <div>
            {{ semesterCharge.totalAmountBilled | dollars }}
          </div>
        </div>
        <div class="flx spread">
          <div>Financial Aid</div>
          <div>
            {{ -semesterCharge.totalFinancialAid | dollars }}
          </div>
        </div>
        <div class="flx spread">
          <div>Other Resources</div>
          <div>
            {{ -semesterCharge.totalOtherResources | dollars }}
          </div>
        </div>
        <hr />
        <div class="flx spread">
          <div>Balance Due For Term</div>
          <div>{{ semesterCharge.totalAmountDue | dollars }}</div>
        </div>
      </app-popover-link>
    </div>
  </li>
  }
</ul>

@if (student) {
<div class="flx spread">
  <h3>Total Due:</h3>
  <div style="font-weight: bold; font-size: 28px;">{{ student.studentAccounts.totalAmountDue | dollars }}</div>
  <app-popover-link iconName="info" label="Details">
    <div class="bill-details-popover">
      <h4>
        For payment options,
        <a [href]="paymentURL" target="_blank">visit our website</a>
      </h4>
      <br />
      A negative balance indicates a pending refund (no payment due).
      <a [href]="refundsURL" target="_blank">See our website for refund options</a>
    </div>
  </app-popover-link>
</div>
}

@if (aMessageShow) {
<dynamic-texts [texts]="aMessage" custom-class="red-font"></dynamic-texts>
}

<button (click)="viewPayAccount()" class="btn full">
  View / Pay Account
</button>

@if (student.studentAccounts.totalAmountDue > 0) {
<h3>International Payments</h3>
}

<ul class="bill-payment">
  @for (semesterCharge of student.studentAccounts.semesterCharges; track $index) {

  @if (semesterCharge.totalAmountDue > 0) {
  <li>
    <button (click)="getWirePaymentQuery(semesterCharge.totalAmountDue)" class="btn full">
      {{ semesterCharge.semesterName | semesterName }}
      International Payment
    </button>
  </li>
  }
  }

  @if (student.studentAccounts.totalAmountDue > 0) {
  <li>
    <button (click)="getWirePaymentQuery(student.studentAccounts.totalAmountDue)" class="btn full">
      Full Balance International Payment
    </button>
  </li>
  }

</ul>