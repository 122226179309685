import { SafePipe } from "./../../pipes/safe.pipe";
import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { ApiService } from "../../services/api.service";
import { AuthService } from "../../services/auth.service";
import { EnvService } from "../../services/env.service";
import { MessageParagraphsPipe } from "../../pipes/messageParagraphs.pipe";
import { MatIconButton } from "@angular/material/button";


interface MenuList {
  label: string;
  items: MenuItems;
  parent?: MenuList;
}

interface MenuItem {
  label: string;
  url: string | null;
}

type MenuItems = (MenuList | MenuItem)[];

@Component({
  standalone: true,
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconButton, MatIconModule,MatMenuModule,  SafePipe, MessageParagraphsPipe],
})
export class PortalHeaderComponent implements OnInit {
  @Input() user;
  @Input() preferredName;
  menu: MenuItems;
  currentMenu:  any;  //   MenuList;

  constructor(
    private authService: AuthService,
    private apiService: ApiService
  ) {}

  async ngOnInit(): Promise<void>{
     this.apiService
        .getAuth(EnvService.BANNER_URL + "/menus", {})
        .then(async (response: any) => {
          const items = JSON.parse(response.menus);
          const root = { label: "Main Menu", items };
          this.menu = this.mapParents(items, root);
          this.currentMenu = root;
        });
  }

  navigate(item: MenuList | MenuItem) {
    if ("items" in item) {
      this.currentMenu = item;
    } else {
      if (!item.url.includes("unsafe")) {
        window.open(item.url, "_blank");
      } else {
        window.open(item.url.split("unsafe ")[1], "_blank");
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  mapParents(items: MenuItems, parent?: MenuList) {
    for (const item of items) {
      if ("items" in item) {
        item.parent = parent;
        item.items = this.mapParents(item.items, item);
      }
    }
    return items;
  }
}
