import { Component, Input } from "@angular/core";
import { StudentResourceService } from "../../../services/student-resource.service";
import { StudentResource } from "../../../models/student-resource.model";
import { EnrollmentTerm } from "../../../models/student-transform.model";

@Component({
  standalone: true,
  selector: "registration-term",
  templateUrl: "registration-term.component.html",
})
export class RegistrationTermComponent {
  @Input() enrollmentTerm: EnrollmentTerm;
  @Input() hasHolds: boolean;
  public viewCourseScheduleResource?: StudentResource; // tslint:disable-line:no-unused-variable
  registrationResource: any;
  constructor(studentResourceService: StudentResourceService) {
    this.viewCourseScheduleResource = studentResourceService.getResource(
      "view-course-enrollment",
      "view-course-schedule"
    );
    this.registrationResource = studentResourceService.getResource(
      "all-students",
      "check-registration-status"
    );
  }
}
