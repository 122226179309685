import { PopoverLinkComponent } from "../../popover-link/popover-link.component";
import { Component, Input, SimpleChanges, OnChanges } from "@angular/core";
import { StudentResource } from "../../../models/student-resource.model";
import { StudentResourceService } from "../../../services/student-resource.service";
import { ErrorService } from "../../../services/error.service";
import {
  EnrollmentAdvice,
  GPALevel,
} from "../../../models/student-transform.model";
import { GpaDetails } from "../../../models/gpa-details.model";

@Component({
  standalone: true,
  selector: "gpas",
  templateUrl: "gpas.component.html",
  imports: [PopoverLinkComponent],
})
export class GpasComponent implements OnChanges {
  private className = "GpasComponent";
  isOpen = false;

  @Input() enrollmentAdvice: EnrollmentAdvice;
  gpaLevels: GPALevel[] = [];
  withdrawalDetailsUri?: StudentResource;
  gpaDetailsPopoverPage: typeof PopoverLinkComponent;

  constructor(
    studentResourceService: StudentResourceService,
    private errorService: ErrorService
  ) {
    const fn: string = +this.className + "#constructor"; // tslint:disable-line:no-unused-variable
    this.gpaDetailsPopoverPage = PopoverLinkComponent;
    this.withdrawalDetailsUri = studentResourceService.getResource(
      "withdrawal-history",
      "withdrawal-details"
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // this only detects changes to the reference to the @Inputs, it will not detect changes to inner properties of the @Inputs
    const fn: string = "?>" + this.className + "#ngOnChanges"; // tslint:disable-line:no-unused-variable
    try {
      if (changes.enrollmentAdvice.currentValue !== undefined) {
        this.gpaLevels = this.getSortedGpaLevels();
      }
    } catch (e) {
      if (!(e instanceof Error)) {
        e = new Error(e);
      }
      this.errorService.logError(e);
      if (!e.message) {
        e.message = "(No message)";
      }
      e.message = fn + "/CATCH: Error in OnChanges -- " + e.message;
      console.error(e);
    }
  }

  getGpaDetails(
    detailType: string,
    gpaLevel: GPALevel
  ): GpaDetails | undefined {
    let gpaDetails: GpaDetails;
    switch (detailType) {
      case "institutional":
        gpaDetails = {
          gpaType: detailType,
          hoursAttempted: gpaLevel.institutionalHoursAttemped,
          hoursEarned: gpaLevel.institutionalHoursEarned,
        };
        return gpaDetails;
      case "hopeScholarship":
        gpaDetails = {
          gpaType: detailType,
          hoursAttempted: gpaLevel.hopeScholarshipHoursAttempted,
          hoursEarned: gpaLevel.hopeScholarshipHoursEarned,
        };
        return gpaDetails;
      case "overall":
        gpaDetails = {
          gpaType: detailType,
          hoursAttempted: gpaLevel.overallHoursAttempted,
          hoursEarned: gpaLevel.overallHoursEarned,
        };
        return gpaDetails;
      default:
        return undefined;
    }
  }

  private getSortedGpaLevels(): GPALevel[] {
    const sortedGpaLevelCodes = ["UA", "US", "GS", "LW"];
    const unsortedGpaLevels = this.enrollmentAdvice.gpaLevels;
    const sortedGpaLevels: Array<GPALevel> = [];
    sortedGpaLevelCodes.forEach((levelCode: string) => {
      const gpaLevel: GPALevel | undefined = this.getGpaLevelByLevelCode(
        unsortedGpaLevels,
        levelCode
      );
      if (gpaLevel) {
        sortedGpaLevels.push(gpaLevel);
      }
    });
    return sortedGpaLevels;
  }

  private getGpaLevelByLevelCode(
    gpaLevels: GPALevel[],
    levelCode: string
  ): GPALevel | undefined {
    return gpaLevels.find((gpaLevel: GPALevel) => {
      return gpaLevel.levelCode === levelCode;
    });
  }
}
