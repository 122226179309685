import { Pipe, PipeTransform } from "@angular/core";
/*
 * Display a number as a string with a leading "$", commas, and 2 digits after the "."
 * Negative values hava a "-" between the "$" and the digits
 * Usage:
 *   value | USD
 * Example:
 *   {{ -1000 |  USD}}
 *   formats to: $-1,000.00
 */
@Pipe({
  standalone: true,
  name: "dollars",
})
export class DollarsPipe implements PipeTransform {
  transform(value: number): string {
    // http://stackoverflow.com/a/2901298/776723 , http://www.javascriptkit.com/javatutors/formatnumber.shtml
    //  TODO: allow leading "$" and commas in value when value is a string
    const parts: string[] = Number(value).toFixed(2).split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return "$" + parts.join(".");
  }
}
