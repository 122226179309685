import { Component, OnInit, Inject } from "@angular/core";
import { LoadingService } from "src/services/loading.service";
import { OpenService } from "src/services/open.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  standalone: true,
  selector: "app-reload",
  templateUrl: "./reload.component.html",
  styleUrls: ["./reload.component.scss"],
})
export class ReloadComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    private dialogRef: MatDialogRef<ReloadComponent>,
    private loading: LoadingService,
    private openService: OpenService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  reload() {
    this.loading.start("Reloading");
    this.openService.reload();
    return false;
  }

  dismiss() {
    this.dialogRef.close();
  }
}
