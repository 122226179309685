@if (show) {
<div class="test-environment-warning">
  <div class="flx">
    <h1>For Testing Only! Information here may not be correct.</h1>
    <div class="actions">
      <mat-checkbox color="warn" [(ngModel)]="doNotShow">Do Not Show Again</mat-checkbox>
      <div>
        <button mat-button color="warn" (click)="hide()">Hide</button>
      </div>
    </div>
  </div>
</div>
}