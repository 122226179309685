import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  standalone: true,
  name: "semesterName",
})
export class SemesterName implements PipeTransform {
  transform(semesterName: string): string {
    const out = `${semesterName.split(" ")[0]} ${semesterName.split(" ")[2]}`;
    return out;
  }
}
