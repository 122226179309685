import { Injectable } from '@angular/core';
import { AdminEngineService } from './admin-engine.service';
import { StudentResource } from '../models/student-resource.model';
import { StudentResources } from '../models/student-resources.model';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})
export class StudentResourceService {
  static resources: StudentResources;

  constructor(private adminEngineService: AdminEngineService) { }

  initialize() {
    return this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, 'student-resources')
      .then((content: any) => {
        StudentResourceService.resources = {};
        for (const key in content) {
          if (content.hasOwnProperty(key)) {
            StudentResourceService.resources[key] = {
              label: content[key].label,
              values: (<StudentResource[]>content[key].values).reduce((agg: any, value) => {
                agg[value.key] = value;
                return agg;
              }, {})
            };

          }
        }
      });
  }

  public getResource(section: string, resource: string): StudentResource | undefined {
    if (StudentResourceService.resources[section] && StudentResourceService.resources[section].values[resource]) {
      return StudentResourceService.resources[section].values[resource];
    }
  }

}
