@if (enrollmentAdvice) {
<div>
  <h3>Degree(s) and Major OR Pathway</h3>

  @if (academicEvaluationUri) {
  <a [href]="academicEvaluationUri.url" target="_blank">Academic Evaluation<i class="material-icons">open_in_new</i></a>
  }

  <ul class="items">
    @for (info of degreeOrMajorInfo; track $index) {
    <li>
      <div class="flx spread indent">
        <h4>{{ info.isDegree ? 'Degree' : 'Major OR Pathway' }}:</h4>
        <div>{{ info.value }}</div>
      </div>
    </li>
    }
  </ul>
</div>
}