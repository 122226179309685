import { MatIconModule } from "@angular/material/icon";
import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { SafePipe } from "../../pipes/safe.pipe";
import { MatButtonModule } from "@angular/material/button";

@Component({
  standalone: true,
  selector: "downtime-component",
  templateUrl: "downtime.component.html",
  styleUrls: ["downtime.component.scss"],
  imports: [SafePipe, MatIconModule, MatButtonModule],
})
export class DowntimeComponent{
  @Input("downtimeMessage") downtimeMessage: any
  public configDowntimeInfo: any;
  constructor(
    public authService: AuthService
  ) {}
}
