import { environment } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn : 'root'
})
export class EnvService {
  static LAB = environment.apiDomain.indexOf('lab') > 0;
  static API_URL = environment.apiDomain;
  static CDN_URL = environment.cdnDomain;
  static GOSOLAR_ROOT = environment.goSolarRoot;
  static PAWS_URL = environment.pawsUrl;
  static GSU_DATA_SERVICE_URL = environment.apiDomain + '/dataService';
  static BANNER_URL = environment.apiDomain + '/dataService' + '/dashboard';
  static ADMIN_ENGINE_CDN_URL = environment.cdnDomain + '/adminenginecache';
  static ADMIN_ENGINE_URL = (environment.apiDomain.indexOf('lab') > 0 ? 'https://api.gsu.edu' : environment.apiDomain) + '/adminEngine';
  static ADMIN_ENGINE_PUBLIC_URL = (environment.apiDomain.indexOf('lab') > 0 ? 'https://api.gsu.edu' : environment.apiDomain) + '/adminEngine' + '/public';
  static UTC_URL = environment.apiDomain + '/dataService' + '/utc';
  static DASHBOARD_APP_ID = environment.apiDomain.indexOf('lab') > 0 ? 'studentDashboard-blab' : 'studentDashboard';
}

