@if (content.length) {
<ul class="items">
  @for (text of content; track $index) {
  <li>
    <p>
      <span class="{{customClass}}">
        <div [innerHtml]="text"></div>
      </span>
    </p>
  </li>
  }
</ul>
}