// const domain = location.origin;
const domain = 'https://cdn-dev.gsu.edu';
export const environment = {
  production: false,
  apiDomain: 'https://api-dev.gsu.edu',
  cdnDomain: 'https://cdn-dev.gsu.edu',
  goSolarRoot: 'https://lum5devssomgr.gsu.edu/ssomanager/c/SSB?pkg=https://www.b8dev.gosolar.gsu.edu/b8dev',
  pawsUrl: 'https://lum5devapp01.gsu.edu',
  loginConfig: {
    prompt: true,
    embedded: undefined,
    redirect: `${domain}/login-hub/authenticate/idp/#?method=redirect`,
    silent: `${domain}/login-hub/authenticate/idp/#?method=silent`,
    popout: undefined,
    logout: `${domain}/login-hub/authenticate/idp/#?method=logout`,
  }
};
